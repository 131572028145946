import React, { useCallback, useMemo, useState, useLayoutEffect } from "react";
import { graphql, navigate } from "gatsby";
import qs from "qs";
import dayjs from "dayjs";
import BoxNews from "../components/box-news";
import Layout from "../components/layout";
import Seo from "../components/seo";
import useClickOut from "../utility/use-click-out";

const hero = [
  {
    title: "Archivio News",
  },
];
const breadcrumb = [{ title: "Home", link: "/" }, { title: "Archivio News" }];

const ArchivioNews = ({ data: { allContentfulNews }, location }) => {
  const news = useMemo(
    () => (allContentfulNews ? allContentfulNews.edges : []).map((edge) => edge.node),
    [allContentfulNews]
  );

  const qsYear = useMemo(() => {
    const query = qs.parse(location.search ? location.search.slice(1) : "");
    const year = parseInt(query.y, 10);
    return isNaN(year) ? null : `${year}`;
  }, [location.search]);

  const years = useMemo(() => {
    return news
      .reduce((years, item) => {
        const year = `${dayjs(item.data).year()}`;
        return years.includes(year) ? years : years.concat([year]);
      }, [])
      .sort((a, b) => (a < b ? 1 : a === b ? 0 : -1));
  }, [news]);

  const [currentYear, setCurrentYear] = useState(years[0]);

  // Sync state from qs to account for ssr first render
  useLayoutEffect(() => {
    if (qsYear && years.includes(qsYear)) {
      setCurrentYear(qsYear);
    }
  }, [years, qsYear]);

  const newsFiltered = useMemo(
    () => news.filter((item) => `${dayjs(item.data).year()}` === currentYear),
    [news, currentYear]
  );

  const [dropdown, setDropdown] = useState(false);
  const toggleDropdown = useCallback(() => setDropdown((value) => !value), []);
  const dropdownRef = useClickOut(dropdown, setDropdown);

  const clickTab = useCallback(
    (e) => {
      setDropdown(false);
      navigate(`${location.pathname}?y=${parseInt(e.target.name)}`);
    },
    [location.pathname]
  );

  return (
    <Layout location={location} hero={hero} breadcrumb={breadcrumb}>
      <Seo title={`Notizie`} description={`Storico delle notizie di Ariadne`} />

      <section className="section ">
        <div className="container">
          <div className="row mb-5">
            <div className="col-12">
              <div className={`dropdown-menu${dropdown ? " open" : ""}`}>
                <button className="dropdown-menu__btn" onClick={toggleDropdown}>
                  {currentYear}
                </button>
                <div
                  ref={dropdownRef}
                  role="tablist"
                  aria-label="anni"
                  className="dropdown-menu__content"
                >
                  {years.map((item, i) => (
                    <button
                      key={i}
                      role="tab"
                      aria-selected={currentYear === item}
                      name={item}
                      className={`dropdown-menu__option${
                        currentYear === item ? " dropdown-menu__option--active" : ""
                      }`}
                      onClick={clickTab}
                    >
                      {item}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {newsFiltered.map((item) => (
              <div key={item.id} className="col-sm-6 col-lg-4">
                <BoxNews data={item} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ArchivioNews;

export const query = graphql`
  query NewsQuery {
    allContentfulNews(
      filter: { node_locale: { eq: "it" } }
      sort: { fields: [data], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          titolo
          data
          friendlyUrl
        }
      }
    }
  }
`;
