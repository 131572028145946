const slugify = require('./slugify');

module.exports = {
  createPaginaInternaPath: (snodo, page) => {
    return `/${slugify(snodo.titolo)}/${slugify(page.titolo || page.titoloLista)}/`;
  },
  createSubPaginaPath: (snodo, page, subPage) => {
    return `/${slugify(snodo.titolo)}/${slugify(page.titolo || page.titoloLista)}/${slugify(subPage.titolo)}/`;
  },
  createNotiziaPath: notizia => {
    const nomeCompleto = notizia.friendlyUrl ? notizia.friendlyUrl : slugify(notizia.titolo);
    return `/archivio-news/${nomeCompleto}/`;
  },
  createParagrafoId: (paragrafo) => slugify(paragrafo.titolo)
}

