import React from "react";
import dayjs from "dayjs";
import "dayjs/locale/it";
import { createNotiziaPath } from "../utility/entity";
import { Link } from "./link";

const BoxNews = ({ data }) => {
  const djsDate = dayjs(data.data).locale("it");
  const eurl = createNotiziaPath(data);

  return (
    <article className="box-news">
      <div className="box-news__info">
        <div className="box-news__date">{djsDate.format("D MMM YYYY")}</div>
        <h2 className="box-news__title">
          <Link to={eurl} title={data.titolo} className="box-news__link">
            {data.titolo}
          </Link>
        </h2>
      </div>
    </article>
  );
};

export default BoxNews;
