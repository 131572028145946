import { useEffect, useRef } from "react";

const useClickOut = (flag, close) => {
  const ref = useRef();
  useEffect(() => {
    if (flag) {
      const listener = (event) => {
        if (event.target !== ref.current && ref.current && !ref.current.contains(event.target)) {
          close();
        }
      };
      const timeout = setTimeout(() => document.addEventListener("click", listener));
      return () => {
        clearTimeout(timeout);
        document.removeEventListener("click", listener);
      };
    }
  }, [flag, close]);
  return ref;
};

export default useClickOut;
